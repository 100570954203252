<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="container">
        <a class="navbar-brand" href="#">Take out and Delivery</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link" style="cursor: pointer;" routerLink="/home" routerLinkActive="active">Home</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="/partners/" target="_blank">TOAD Delivery Partners</a>
                </li>

                <!-- <li class="nav-item">
                    <a class="nav-link" href="/dod/" target="_blank">Delivery on Demand</a>
                </li> -->

                <li class="nav-item">
                    <a class="nav-link" href="/register/" target="_blank">Register</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="/restaurant/" target="_blank">Restaurant</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="/dispatch/" target="_blank">Dispatch</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="/driver/" target="_blank">Driver</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="/dcadmin/" target="_blank">DAdmin</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="/admin/" target="_blank">SAdmin</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" style="cursor: pointer;" routerLink="/mobileApps" routerLinkActive="active">Mobile Apps</a>
                </li>
            </ul>
        </div>
    </div>
</nav>

<!-- Header -->
<header class="bg-primary mt-4">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <h1 class="display-4 text-white m-2">TOADApp</h1>
                <p class="lead text-white-75 ml-3">The TOADApp suite of applications provide an integrated end-to-end experience for delivery of products to valued consumers.</p>
            </div>
        </div>
    </div>
</header>

<router-outlet></router-outlet>

<!-- Footer -->
<footer class="py-5 bg-dark">
    <div class="container">
        <p class="m-0 text-center text-white">Copyright &copy; toadapp.ca 2024</p>
    </div>
    <!-- /.container -->
</footer>