<!-- Page Content -->
<div class="container-fluid">

    <div class="row mt-3">
        <div class="col-md-8 mb-3">
            <h2 class="text-left">What is 'TOADApp'?</h2>
            <p class="ml-3">TOADApp is short for 'Take Out And Delivery', our suite of applications act as a 'broker', that enables dispatch companies to assign and track deliveries.</p>
        </div>

        <div class="col-md-4 mb-3">
            <h2>Contact Us</h2>
            <address>
                <a href="tel:226-289-6492">
                    <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;
                    226.289.6492
                </a>
                <br>
                <a href="mailto:info@toadapp.ca" target="_blank">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;
                    info@toadapp.ca
                </a>
            </address>
        </div>
    </div>

    <div class="row">

        <div class="col-lg-4 col-md-6 col-sm-12 p-2">
            <div class="card h-100">
                <div class="card-body">
                    <h4 class="card-title">How do we differ?</h4>
                    <img class="card-img-top" src="assets/img/light-bulbs.jpg" alt="Our Offering">
                    <ul class="card-text mt-1">
                        <li>Firstly our dispatch affiliates will not charge a 'commision' based on the food sale; rather a fixed delivery fee.</li>
                        <li>We don't own your menu.  You can use whatever product you like.  We also have affiliates that don't rob you either.</li>
                        <li>Agnostic delivery. (Drivers can't Cherry Pick)</li>
                    </ul>
                </div>
                <div class="card-footer text-right">
                    <a href="#" class="btn btn-primary">Find Out More!</a>
                </div>
            </div>
        </div>


        <div class="col-lg-4 col-md-6 col-sm-12 p-2">
            <div class="card h-100">
                <div class="card-body">
                    <h4 class="card-title">Our Offering</h4>
                    <img class="card-img-top" src="assets/img/coins.jpg" alt="Our Offering">
                    <p class="card-text mt-1">In this world of high commissions being paid to some faceless corporation on everything you sell, we offer a change! Keep your money; well, the Lion’s share at least. Our product is 100% Canadian developed and operated.
                    </p>
                </div>
                <div class="card-footer text-right">
                    <a href="#" class="btn btn-primary">Find Out More!</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 p-2">
            <div class="card h-100">
                <div class="card-body">
                    <h4 class="card-title">Restaurant</h4>
                    <img class="card-img-top" src="assets/img/outdoor-restaurant.png" alt="Restaurant">
                    <p class="card-text mt-1">The ‘Restaurant’ application that you, the restaurant entity will use locally. This intuitive application allows the operator to quickly enter in minimal details about a delivery request.</p>
                </div>
                <div class="card-footer text-right">
                    <a href="#" class="btn btn-primary">Find Out More!</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 p-2">
            <div class="card h-100">
                <div class="card-body">
                    <h4 class="card-title">Dispatch</h4>
                    <img class="card-img-top" src="assets/img/airtrafficcontrol.png" alt="Dispatch">
                    <p class="card-text mt-1">The ‘Dispatch’ application, as you guessed, is for the dispatchers. This application is used to acknowledge the request from the restaurant, and allows the dispatcher to assign a driver to the order.</p>
                </div>
                <div class="card-footer text-right">
                    <a href="#" class="btn btn-primary">Find Out More!</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 p-2">
            <div class="card h-100">
                <div class="card-body">
                    <h4 class="card-title">Driver</h4>
                    <img class="card-img-top" src="assets/img/rickshaw.png" alt="Driver">
                    <p class="card-text mt-1">The ‘Driver’ application is used by… the driver. They will interact with the dispatcher using this app. </p>
                </div>
                <div class="card-footer text-right">
                    <a href="#" class="btn btn-primary">Find Out More!</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 p-2">
            <div class="card h-100">
                <div class="card-body">
                    <h4 class="card-title">Safety First</h4>
                    <img class="card-img-top" src="assets/img/Hyderabad_Traffic_Police_Safety.jpg" alt="Safety First">
                    <p class="card-text mt-1">As dispatch and delivery operations are mostly done on the road, safety of our people is a priority. So to avoid constant monitoring of the applications, which is a distraction, the dispatcher and drivers will receive SMS messages to alert them to new
                        actions
                        they must take. At which point they will address the alert when it is safe.</p>
                </div>
                <div class="card-footer text-right">
                    <a href="#" class="btn btn-primary">Find Out More!</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 p-2">
            <div class="card h-100">
                <div class="card-body">
                    <h4 class="card-title">Intuitive Interface</h4>
                    <img class="card-img-top" src="assets/img/LucyEthel.jpg" alt="Simple Interface">
                    <p class="card-text mt-1">Keeping the User Interface as simple to use as possible makes it easy for most people to operate the application effortlessly.
                        All the applications are ‘web browser based’. This is by design, so that it can work on a multitude of devices; including Mobile Devices/Phones, PCs, Linux, or even a Mac.
                    </p>
                </div>
                <div class="card-footer text-right">
                    <a href="#" class="btn btn-primary">Find Out More!</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 p-2">
            <div class="card h-100">
                <div class="card-body">
                    <h4 class="card-title">But wait... There's more!</h4>
                    <img class="card-img-top" src="assets/img/waittheresmore.png" alt="">
                    <p class="card-text mt-1">The applications can be used in multiple locations as well; e.g. Front-of-House ro Kitchen where the delivery details may be entered, as well as the back-office, where they can keep an eye on operations.</p>
                    <p class="card-text mt-1">As a broker of delivery services, our API's are available to Online Ordering/Menu systems to use.</p>
                </div>
                <div class="card-footer text-right">
                    <a href="#" class="btn btn-primary">Find Out More!</a>
                </div>
            </div>
        </div>
    </div>

</div>
