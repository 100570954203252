<div class="align-items-center">
    <div class="container"> <a class="nav-link" style="cursor: pointer;" routerLink="/homed">Home</a>
        <div class="row">
            <div class="col">
                <div class="card"> <img src="assets/img/rickshaw.png" class="card-img-top">
                    <div class="card-body">
                        <h5 class="card-title">Delivery on Demand</h5>
                        <p class="card-text">
                            Possibly the most convienient way to request a delivery from anywhere to your
                            locaiton. Just install the app to your mobile phone, and away you go.
                        </p>
                        <a href="https://urlgeni.us/P1mVL">Mobile Play Store</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card"> <img src="assets/img/rickshaw.png" class="card-img-top">
                    <div class="card-body">
                        <h5 class="card-title">Driver App</h5>
                        <p class="card-text"> For any TOADApp Delivery Agents</p>
                        <a href="https://urlgeni.us/iUZPF">Mobile Play Store</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card"> <img src="assets/img/rickshaw.png" class="card-img-top">
                    <div class="card-body">
                        <h5 class="card-title">Dispatch App</h5>
                        <p class="card-text"> For any TOADApp Dispatch Agents</p>
                        <a href="https://urlgeni.us/jE6py">Mobile Play Store</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>