<div class="align-items-center">
    <div class="container">
        <h2>Delete Your <b>TOADApp - Delivery On Demand</b> Account</h2>
        <br/>
        Using the DoD mobile app:
        <ul>
            <li>Login as normal. (We need your internal account id)</li>
            <li>Navigate to settings at the top right (three dots)</li>
            <li>Select 'Policy'</li>
            <li>Scroll to the Bottom of the screen.</li>
            <li>Select the <b>Delete my Account!</b> options.</li>
            <li>Select <b>Yes</b> to the confirm prompt.</li>
            <br/>
            <li>If you no longer have access to the app, you can email <a href="mailto:info@toadapp.ca">info@toadapp.ca</a> to have it removed.</li>
            <li>All Accounts are removed after 90 days of non-use.</li>
        </ul>
        <br/>
        <p>Bob's your uncle.</p>
        <p>Sorry to see you go!</p>
        <p>Please provide some feedback so we can make the app better.</p>
        <p>Note: The app is only an interface to a delivery company in your area.  If deliveries are slow or in error, we cannot help.</p>
    </div>
</div>