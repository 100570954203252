import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { DeleteMyDoDAccountComponent } from './delete-my-do-daccount/delete-my-do-daccount.component';
import { MobileAppsComponent } from './mobile-apps/mobile-apps.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'deleteMyDoDAccount', component: DeleteMyDoDAccountComponent },
  { path: 'mobileApps', component: MobileAppsComponent },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
